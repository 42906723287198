import React from 'react'
import {Route, Switch} from 'wouter'
import Login from '@ai-antd/page/Login'
import Home from './page/Home'
import About from '@ai-antd/page/About'
import SignUp from '@ai-antd/page/SignUp'
import Workspace from '@ai-antd/page/Workspace'
import Demo from '@ai-antd/components/Demo'
import Icon from '@ai-antd/components/Icon'
import Wall from '@ai-antd/components/Wall'
import SiteLayout from '@ai-antd/page/SiteLayout'
import {navItems} from './navItems'
import reduce from 'lodash/reduce'
import app from './App.json'
import './App.less'
import Account from '@oven/ng-ai-ads/src/page/Account'

const getRoute = ({group, title: groupTitle, icon: groupIcon= null}, {template, title: featureTitle, icon: templateIcon, ...rest} = {}) => {
  return (
    <Route path={'/' + template || group} key={template || group}>
      <Workspace
        group={group}
        title={featureTitle || groupTitle}
        template={template}
        icon={<Icon name={templateIcon || groupIcon} />}
        {...rest}
        app={app}
      />
    </Route>
  )
}

const App = () => {
  return (
    <SiteLayout name={app.name} domain={app.domain} navItems={navItems}>
      <Switch>
        <Route path="/">
          <Home />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/demo/:group">
          {({group}) => <Demo
            group={group}
            dashboard={app.dashboard}
          />}
        </Route>
        <Route path="/demo/:group/:template">
          {({group, template}) => <Demo
            group={group}
            template={template}
            dashboard={app.dashboard}
          />}
        </Route>
        <Route path="/login">
          <Login appName={app.name} />
        </Route>
        <Route path="/sign-up">
          <SignUp />
        </Route>
        {
          reduce(app.dashboard, (routes, {features = [], ...group}) => {
            if (!features?.length) routes.push(getRoute(group))
            features.forEach(feature => {
              routes.push(getRoute(group, feature))
            })
            return routes
          }, [])
        }
        <Route path="/account">
          <Account />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/about/:tab">
          {({tab}) => <About tabKey={tab}/>}
        </Route>
        <Route path="/wall">
          <Wall>
            <img src="/images/app-icon-512.png" alt="davinci fn-ai.io this is a wall." width={350} />
          </Wall>
        </Route>
      </Switch>
    </SiteLayout>
  )
};
export default App
