import React, {useContext} from 'react'
import {Button, Carousel, Divider, Tabs, Typography} from 'antd'
import {Link, useLocation} from 'wouter'
import FeaturesList from './FeaturesList'
import reduce from 'lodash/reduce'
import Industries from './Industries'
import {ga} from '@omo/lib/firebase'
import {WindowContext} from './context'
import SplashCard, {contentStyle} from './SplashCard'

const examplesStyle = {
  ...contentStyle,
  padding: '20px',
}
export const Splash = ({app}) => {
  const [, setLocation] = useLocation()
  const [isMobile] = useContext(WindowContext)
  let slider
  const navigateNext = e => {
    slider.next()
    ga.click(e)
  }
  const navigateToLogin = (e)=> {
    setLocation('/login')
    ga.click(e)
  }
  return (
    <Carousel
      ref={sliderRef => slider = sliderRef}
      className="splash-carousel-dots"
      adaptiveHeight
      arrows={!isMobile}
    >
      {
        app?.home?.splash?.map((splash, index) => ((!splash.hidden && <SplashCard
          key={`home-splash-${index}`}
          group="home"
          splash={splash}
          navigateNext={navigateNext}
        />) || null))
      }
      {
        reduce(app.dashboard, (accumulator, {splash, group}) => {
          if (splash) accumulator.push(
            <SplashCard
              key={group}
              group={group}
              splash={splash}
              isFeature
            />
          )
          return accumulator
        }, [])
      }
      <div>
        <div style={examplesStyle}>
          <Typography.Title type="warning">Everything You Need.</Typography.Title>
          <Tabs
            items={[
              {label: "Features", key: "features", children: <FeaturesList dashboard={app.dashboard} />},
              {label: "Industries", key: "industries", children: <Industries />}
            ]}
            tabBarExtraContent={isMobile && <Link to="/login" onClick={ga.click}><Button title="Start Now: Splash Last" type="primary">Start Now</Button></Link>}
          />
          <Divider />
          <Button
            size="large"
            type="primary"
            block
            onClick={navigateToLogin}>
            Get Started Today
          </Button>
          <Divider />
        </div>
      </div>
    </Carousel>
  )
}
