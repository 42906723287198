import React from 'react'
import {Space, Typography} from 'antd'
import {ProgressBar} from './ProgressBar'
import {FrownOutlined} from '@ant-design/icons'
import ContentActions from './ContentActions'
import {colls} from '@omo/lib/firestore.db'

export default function BrainstormAnswer({messageId, message = {}}) {
  return (
    <ContentActions
      contentId={messageId}
      content={message}
      collection={colls["openai-brainstorm"]}
    >
      <span className="brainstorm-message left">
      <Space direction="vertical">
        {
          message.error ?
            <FrownOutlined /> :
            message.completion ?
              <Typography.Paragraph copyable={false}>
                <p className="content-text"
                   dangerouslySetInnerHTML={{__html: message?.completion?.choices?.[0]?.text?.trim()}}
                />
              </Typography.Paragraph>
              :
              <ProgressBar visible float={false} />
        }
      </Space>
      </span>
    </ContentActions>
  )
}
