import React from 'react'
import {ProgressBar} from './ProgressBar'
import {Space, Tag, Typography} from 'antd'

const DemoContent = ({content, isProcessing = false}) => {
  return (
    <div>
      <div style={{position: 'relative'}}>
        <div className="content-demo-tag-strip">
          <div>
            <ProgressBar visible={isProcessing} float={false} />
          </div>
          <Space>
            <Tag hidden={!content?.templateParams?.audience}>{content?.templateParams?.audience}</Tag>
            <Tag hidden={isProcessing || !content?.templateParams?.tone}>{content?.templateParams?.tone}</Tag>
            <Tag>Ai</Tag>
          </Space>
        </div>
        <Typography.Paragraph type={isProcessing ? "secondary" : "success"}>
          <p className="content-text large" dangerouslySetInnerHTML={{__html: content?.completion?.choices?.[0]?.text?.trim()}} />
        </Typography.Paragraph>
      </div>
    </div>
  )
}
export default DemoContent
