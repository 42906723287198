import React from 'react'
import {Button} from 'antd'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '@omo/lib/firebase'
import {useLocation} from 'wouter'

export default function UpsellButton() {
  const [user, loading] = useAuthState(auth)
  const [location, setLocation] = useLocation()
  const onGetStartedClick = () => setLocation('/login')
  if (loading) return null
  return (!user || user?.isAnonymous) && location !== "/login"
    ? <Button type="primary" onClick={onGetStartedClick}>Get Started</Button>
    : null
}