import React, {useState} from 'react'
import {Alert, Button, Carousel, Divider, notification, Space, Typography} from 'antd'
import {Link} from 'wouter'
import Editor from './Editor'
import {EditOutlined, HomeOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons'
import DemoContent from './DemoContent'
import Tone from './Tone'
import * as api from '@omo/lib/callable'
import FeaturesList from './FeaturesList'
import {ga} from '@omo/lib/firebase'

const contentStyle = {
  minHeight: '600px',
  boxSizing: 'border-box',
  padding: '10px',
}
const getFeatureToDemo = (group, template, dashboard) => {
  const {features = []} = dashboard.find(item => item.group === group) || {}
  return template
    ?  features.find(feature => feature.template === template)
    : features.find(feature => !!feature.demo)
}
const Demo = ({group, template, dashboard}) => {
  const [processing, setProcessing] = useState(false)
  const [requestCount, setRequestCount] = useState(0)
  const [result, setResult] = useState([])
  const [promptOptions, setPromptOptions] = useState()
  const [modelOptions, setModelOptions] = useState()
  const {demo, ...feature} = getFeatureToDemo(group, template, dashboard)
  let slider
  const navigateNext = () => slider.next()
  const navigatePrev = () => slider.prev()
  const onRequestStart = () => {
    setRequestCount(requestCount + 1)
    setProcessing(true)
  }
  const onRequest = template => {
    onRequestStart()
    ga.log('api-call-completion', {value: template})
  }
  const onInitialRequest = (template) => {
    navigateNext()
    onRequest(template)
  }
  const onResponse = (data, promptOptions, modelOptions) => {
    setProcessing(false)
    setResult(data || [])
    setPromptOptions(promptOptions)
    setModelOptions(modelOptions)
  }
  const requestWithTone = tone => {
    sendRequest({promptOptionsOverrides: {tone}})
  }
  const sendRequest = async ({promptOptionsOverrides = {}, modelOptionsOverrides = {}}) => {
    onRequest()
    const updatedPromptOptions = {...promptOptions, ...promptOptionsOverrides}
    const updatedModelOptions = {...modelOptions, ...modelOptionsOverrides}
    api.completion({
      promptOptions: updatedPromptOptions,
      modelOptions: updatedModelOptions,
    })
      .then(({data}) => {
        onResponse && onResponse(data, updatedPromptOptions, updatedModelOptions)
        ga.log('api-success-content', {value: template, demo: true})
      }).catch(e => {
        onResponse()
        notification.error({
          message: 'An error occurred.',
          description: e?.message === 'internal' ? 'Unable to complete your request. Try again later.' : e?.message,
        })
        console.log('error', e, e.code)
      })
  }
  if (demo.hidden) return (
    <Space direction="vertical" style={{width: "100%"}}>
      <Alert type="warning" message="That demo is not currently available." showIcon />
      <Space>
        <Link to="/">
          <Button icon={<HomeOutlined />} size="large" />
        </Link>
        <Link to="/login">
          <Button type="primary" size="large" block>Login to Continue</Button>
        </Link>
      </Space>
    </Space>
  )
  return (
    <Carousel
      ref={sliderRef => slider = sliderRef}
      className="splash-carousel-dots"
      adaptiveHeight
      infinite={false}
      dots={false}
      draggable={false}
      swipe={false}
    >
      {demo.intro?.map(({title, subTitle, cta = "Continue"}) => {
        return (
          <div key={title}>
            <div style={contentStyle}>
              <Typography.Title type="warning" level={4}>{title}</Typography.Title>
              <Typography.Title>{subTitle}</Typography.Title>
              <Divider />
              <Space align="end">
                <Button size="large" onClick={navigateNext} icon={<RightOutlined />}>{cta}</Button>
              </Space>
            </div>
          </div>
        )
      })}
      <div>
        <div style={contentStyle}>
          <Editor
            isDemo
            group={group}
            {...feature}
            onRequest={onInitialRequest}
            onResponse={onResponse}
          />
          <Divider />
        </div>
      </div>
      <div>
        <div style={contentStyle}>
          <DemoContent
            isProcessing={processing}
            contentId={result && result[0]}
            content={result && result[1]}
          />
          {!processing && !result?.[1]?.completion && <Button size="large" icon={<LeftOutlined />} onClick={navigatePrev}>Try Again</Button>}
          <Divider />
          <Space hidden={!result[1]} direction="vertical">
            <Typography.Text type="secondary">Try a different tone</Typography.Text>
            <Tone
              tones={["Creative","Witty", "Formal", "Fun"]}
              disabled={processing}
              onSelect={requestWithTone}
            />
          </Space>
          <Divider />
          <Space hidden={!result[1]}>
            <Button size="large" icon={<EditOutlined />} onClick={navigatePrev} />
            <Divider direction="vertical" />
            <Button size="large" icon={<RightOutlined />} onClick={navigateNext}>More</Button>
          </Space>
        </div>
      </div>
      <div>
        <div style={contentStyle}>
          <Typography.Title type="warning">Everything You Need</Typography.Title>
          <FeaturesList />
          <Divider />
          <Link to="/login" onClick={ga.click}><Button title="Get Started Free" block type="primary" size="large">Get Started Free</Button></Link>
        </div>
      </div>
    </Carousel>
  )
}

export default Demo
