import React, {useState} from 'react'
import {Tag} from 'antd'
import random from 'lodash/random'

const RandomPrompt = ({prompts = [], onSelect, hidden}) => {
  const [index, setIndex] = useState(random(0, prompts.length - 1))
  if (!prompts.length || hidden) return null
  const onClick = () => {
    onSelect(prompts[index])
    setIndex(random(0, prompts.length - 1))
  }
  return (
    <Tag
      className="editor-action-button"
      onClick={onClick}
    >
      TRY RANDOM EXAMPLE
    </Tag>
  )
}

export default RandomPrompt
