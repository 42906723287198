import React from 'react'
import reduce from 'lodash/reduce'
import {Button, Collapse, Space, Typography} from 'antd'
import {Link} from 'wouter'
import Icon from './Icon'

const FeaturesList = ({dashboard}) => {
  return (
    <Collapse accordion expandIconPosition="end" ghost bordered={false} >
      {
        reduce(dashboard, (accumulator, {group, features, icon}) => {
          features.forEach(({template, title, description, live, privateAccess, demo}) => {
            accumulator.push(
              <Collapse.Panel
                header={<Space><Icon name={icon} /><Typography>{title}</Typography></Space>}
                key={template}
              >
                <Space direction="vertical">
                  <p>{description}</p>
                  <Space>
                    {demo && !demo.hidden && <Link to={`/demo/${group}/${template}`}><Button size="large">Demo</Button></Link>}
                    <Link to="/login"><Button size="large">Get Started Now</Button></Link>
                    {/*<FeatureStatus live={live} privateAccess={privateAccess} />*/}
                  </Space>
                </Space>
              </Collapse.Panel>
            )
          })
          return accumulator
        }, [])
      }
    </Collapse>
  )
}

export default FeaturesList
