import React, {useState} from 'react'
import {Card, List, Typography, Tag, Space, Button, Divider, Row, Col, message} from 'antd'
import {PictureOutlined} from '@ant-design/icons'
import {colls} from '@omo/lib/firestore.db'
import ContentActions from './ContentActions'

const copyable = (showTip, isHtml) => ({
  text: "",
  onCopy: () => message.success('Copied'),
  icon: <Tag size="small" color="blue">COPY</Tag>,
  tooltips: false,
  format: (isHtml ?  'text/html' : 'text/plain'),
})
const getImage = ({linkData, prompt}) => {
  const imageUrl = linkData?.image?.[0]
  if (!imageUrl) return null
  const alt = linkData.title || prompt
  return <img alt={alt} src={imageUrl} />
}
const cardStyle = { width: '100%' }
const ContentListItem = ({contentId, content = {}, canDelete = true, canLike = true, canSave = true}) => {
  const {completion = {}, templateParams = {}, group = "Ai"} = content;
  const [preview, setPreview] = useState(false)
  const cover = preview && getImage(templateParams)
  const togglePreview = () => setPreview(!preview)
  return (
    <List.Item>
      <ContentActions contentId={contentId} content={content} collection={colls['openai-content']} allowSave>
        <Card
          style={cardStyle}
          cover={cover}
        >
          <Card.Meta
            title={[
              <Tag key="ai">{group}</Tag>,
              <Tag hidden={!templateParams?.tone} key="tone">{templateParams?.tone}</Tag>,
              <Tag hidden={!templateParams?.audience} key="audience">{templateParams?.audience}</Tag>
            ]}
            description={
              <Space direction="vertical">
                <Divider className="small" />
                <Typography.Paragraph copyable={copyable()}>
                  <p className="content-text medium" dangerouslySetInnerHTML={{__html: completion?.choices?.[0]?.text?.trim()}} />
                </Typography.Paragraph>
              </Space>
          }
          />
          <Row justify="space-between">
            <Col />
            <Col>
              <Space.Compact>
                {templateParams?.linkData?.image?.[0] && <Button icon={<PictureOutlined/>} onClick={togglePreview}/>}
              </Space.Compact>
            </Col>
          </Row>
        </Card>
      </ContentActions>
    </List.Item>
  )
}

export default ContentListItem
