import React from 'react'
import {Alert, List} from 'antd'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '@omo/lib/firebase'
import BrainstormAnswer from './BrainstormAnswer'
import BrainstormPrompt from './BrainstormPrompt'
import {buildQuery, colls} from '@omo/lib/firestore.db'
import useQueryFirestore from '@omo/hooks/useQueryFirestore'
import Loading from './Loading'
import reduce from 'lodash/reduce'

const getListItem = ([messageId, message]) => {
  const props = {key: messageId, messageId, message}
  return message.promptOptions ?
    <BrainstormPrompt {...props} /> :
    <BrainstormAnswer {...props} />
}
const BrainstormThread = ({mode = 'qa', setHistory}) => {
  const [user] = useAuthState(auth)
  const [pairs = [], dataLoading, error] = useQueryFirestore(
    buildQuery(
      colls['openai-brainstorm'],
      {'==': {userId: user.uid}},
      {order: [['created', 'desc']]}))
  setHistory(reduce(pairs, (history, [, message]) => {
    if (!message?.completion) return history
    return history.push([message?.templateParams?.prompt, message?.completion?.choices?.[0].text]) && history
  }, []).reverse())
  return <section className="brainstorm-thread-wrapper">
    {!!error && <Alert message="Unable to load content at this time." type="error" />}
    {dataLoading && <Loading/>}
    <List
      className="brainstorm-thread"
      split={false}
      hidden={pairs.length === 0}
      dataSource={pairs.reverse()}
      renderItem={getListItem}
    />
  </section>
}

export default BrainstormThread
