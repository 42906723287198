import React, {useState} from 'react'
import {Button, Col, Divider, Input, notification, Row, Space, Typography} from 'antd'
import {LockFilled, MailOutlined} from '@ant-design/icons'
import { useAuthState } from 'react-firebase-hooks/auth'
import {auth} from '@omo/lib/firebase'
import { updateProfile } from 'firebase/auth'
import {RequireAuth} from '@ai-antd/components/RequireAuth'

const spaceFlexStyle = {display: 'flex'}

const Account = () => {
  const [user] = useAuthState(auth)
  const [displayName, setDisplayName] = useState(user?.displayName || '')
  const [changed, setChanged] = useState(false)
  const onClickLogout = () => {
    user && auth.signOut()
  }
  const onClickSave = () => {
    if (!changed) return
    updateProfile(user, {
      displayName,
    }).then(() => notification.success({message: 'Profile Updated'}))
  }
  const onChangeDisplayName = e => {
    setDisplayName(e.target.value)
    setChanged(true)
  }
  return (
    <RequireAuth>
      <Row>
        <Col sm={24} md={24} lg={10} xl={10} xxl={8}>
          <Space direction="vertical" style={spaceFlexStyle}>
            <Typography.Title level={2}>User</Typography.Title>
            <label>Display Name</label>
            <Input size="large" placeholder="Display Name" defaultValue={displayName} onChange={onChangeDisplayName} />
            <Typography.Text type="secondary">Email</Typography.Text>
            <Input disabled size="large" placeholder="Email Address" prefix={<MailOutlined />} value={user?.email} suffix={<LockFilled />} />
            <Divider />
            <Space>
              <Button type="warning" onClick={onClickLogout}>Log out</Button><Button disabled={!changed} onClick={onClickSave} type="primary">Save</Button>
            </Space>
          </Space>
        </Col>
        <Col sm={0} md={0} lg={10} xxl={8}></Col>
        <Col sm={0} md={0} lg={0} xxl={8}></Col>
      </Row>
    </RequireAuth>
  )
}

export default Account
