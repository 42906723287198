import {Button, Drawer, Space, Typography} from 'antd'
import React, { useState } from 'react'
import {ga} from '@omo/lib/firebase'
import {HiMenuAlt1} from 'react-icons/hi'
import NavMenu from './NavMenu'
import {Link} from 'wouter'

const iconStyle = {textAlign: 'left'}

const MobileSidebar = ({navItems = [], width = 260}) => {
  const [open, setOpen] = useState(false)
  const showDrawer = () => {
    setOpen(true)
    ga.log('open-nav-drawer')
  }
  const onClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Button
        style={iconStyle}
        size="large"
        type="ghost"
        className="border-none"
        icon={<HiMenuAlt1 size={26} />}
        onClick={showDrawer}
      />
      <Drawer
        title={<Typography.Title className="drawer-title" level={4}>Do More.</Typography.Title>}
        placement="left"
        width={width}
        onClose={onClose}
        open={open}
        className="mobile-drawer"
        closable={false}
        footer={<Space>
          <Link to="/about" onClick={onClose}>About</Link>
          <Link to="/about/terms" onClick={onClose}>Terms of Service</Link>
      </Space>}
      >
        <NavMenu onNav={onClose} items={navItems} />
      </Drawer>
    </>
  );
};
export default MobileSidebar
