import React, { useState } from 'react'
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth'
import {Typography, Button, Input, Space, Divider, notification} from 'antd'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '@omo/lib/firebase'
import {Link, Redirect} from 'wouter'
import {LockOutlined, MailOutlined} from '@ant-design/icons'
import {SignUpButton} from '@ai-antd/components/SignUpButton'
import LoginLinks from '@ai-antd/components/LoginLinks'

const signInEmail = async (e, p) => {
  if (!e || !p) return notification.warning({message: 'Missing Info', description: 'Enter Email & Password'})
  await setPersistence(auth, browserLocalPersistence)
  await signInWithEmailAndPassword(auth, e, p).catch((err) => notification.error({message: 'Login failed', description: 'Invalid Email or Password'}))
}
const Login = ({appName}) => {
  const [user] = useAuthState(auth);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  if (user && !user.isAnonymous) return <Redirect to='/' />
  return (
    <section style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
      <Typography.Title level={2} style={{margin: '20px 40px 0'}}>Do More.</Typography.Title>
      <Space direction="vertical" align="center" style={{margin: ' 20px auto 0', width: '100%'}}>
        <div>
          <Space direction="vertical">
            <Divider>Login</Divider>
            <Input size="large" prefix={<MailOutlined />} onChange={e => setEmail(e.target.value)} placeholder="Email" />
            <Input size="large" prefix={<LockOutlined />} onChange={e => setPassword(e.target.value)} placeholder="Password" type="password" />
            <Button size="large" block onClick={() => signInEmail(email, password)} type="primary">Log In</Button>
            <Divider />
            <SignUpButton text="Create Account" />
            <LoginLinks appName={appName} />
          </Space>
        </div>
      </Space>
    </section>
  )
}

export default Login
