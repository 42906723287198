import React from 'react'
import {Button, Divider, Space, Typography} from 'antd'
import {Link, useLocation} from 'wouter'
import TagsList from './TagsList'
import {ga} from '@omo/lib/firebase'
import {RightOutlined} from '@ant-design/icons'

export const contentStyle = {
  minHeight: '600px',
  boxSizing: 'border-box',
  padding: '40px',
}

const getHomeActions = ({navigateToLogin, navigateNext}) => {
  return (
    <Space>
      <Button
        title="Splash Landing: Get Started"
        size="large"
        onClick={navigateToLogin}
      >
        Get Started
      </Button>
      <Button
        title="Splash Landing: Tell Me More"
        size="large"
        icon={<RightOutlined />}
        onClick={navigateNext}>
        Tell me more
      </Button>
    </Space>
  )
}

const getFeatureActions = ({group, navigateToLogin, navigateNext}) => {
  return (
    <Space>
      <Button
        title={`Splash ${group}: Get Started`}
        size="large"
        onClick={navigateToLogin}
      >
        Get Started
      </Button>
      <Link
        to={`/demo/${group.toLowerCase()}`}
        onClick={ga.click}>
        <Button
          title={`Splash ${group}: Demo`}
          size="large"
          type="primary"
        >
          Demo
        </Button>
      </Link>
    </Space>
  )
}

export default function SplashCard({group, splash, isFeature, navigateNext}) {
  const [, setLocation] = useLocation()
  const navigateToLogin = (e)=> {
    setLocation('/login')
    ga.click(e)
  }
  const actions = isFeature
    ? getFeatureActions({group, navigateToLogin, navigateNext})
    : getHomeActions({navigateToLogin, navigateNext})
  return (
    <div>
      <div style={contentStyle}>
        <Typography.Title type="warning">{splash.title}.</Typography.Title>
        <Typography.Title>{splash.subTitle}</Typography.Title>
        <TagsList tags={splash.tags} type="secondary" />
        <Divider />
        <Space direction="vertical">
          {actions}
          {splash?.extra?.text && !splash?.extra?.hidden && <Link className="splash-extra-link" to={splash.extra?.url}>{splash.extra.text}</Link>}
        </Space>
      </div>
    </div>
  )
}