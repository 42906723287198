import React, {useState} from 'react'
import useQueryFirestore from '@omo/hooks/useQueryFirestore'
import {buildQuery, colls} from '@omo/lib/firestore.db'
import {Select} from 'antd'

export const defaultModel = 'text-davinci-003'
export default function SelectModel({onSelect, style = null}) {
  const [model, setModel] = useState([defaultModel])
  const [pairs = []] = useQueryFirestore(
    buildQuery(
      colls['openai-models'],
      {},
      {order: [['created', 'desc']], limit: 100})
  )
  const onChange = value => {
    setModel(value)
    onSelect(value)
  }
  const items = pairs.map(([value]) => ({value, label: value}))
  return (
    <Select
      defaultValue={defaultModel}
      value={model}
      options={items}
      onChange={onChange}
      style={style}
    />
  )
}