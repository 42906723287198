import {notification} from 'antd'
import {ga} from '@omo/lib/firebase'

const placement = 'top'
export const notifyAndTrack = {
  error: options => {
    notification.error({placement, ...options})
    ga.notification('error', options)
  },
  warning: options => {
    notification.warn({placement, ...options})
    ga.notification('warning', options)
  },
  warn: options => notifyAndTrack.warning({placement, ...options}),
  info: options => {
    notification.info({placement, ...options})
    ga.notification('info', options)
  },
  success: options => {
    notification.success({placement, ...options})
    ga.notification('success', options)
  }
}
