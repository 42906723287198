import {collection, doc, limit, orderBy, query, where, updateDoc, addDoc, setDoc, deleteDoc} from 'firebase/firestore'
import {firestore} from './firebase'
import now from 'lodash/now'
import reduce from 'lodash/reduce'
import flatten from 'lodash/flatten'
export const colls = {
  'openai-models': 'openai-models',
  'openai-content': 'openai-content',
  'openai-brainstorm': 'openai-brainstorm',
  'user-tips': 'user-tips',
  'user-audience': 'user-audience',
  'wait-list': 'wait-list',
}
export const toDataPairs = (querySnapshot) => {
  if (!querySnapshot?.size) return []
  const pairs = []
  querySnapshot.size && querySnapshot.docs.forEach(snapshot => pairs.push([snapshot.id, snapshot.data()]))
  return pairs
}
export const generateID = () => doc(collection(firestore, 'dummy')).id
export const buildQuery = (collectionName, fields, {limit: queryLimit = 10, order = []} = {}) => {
  if (!colls[collectionName]) throw Error(`Collection name "${collectionName}" is not valid.`)
  return query(
    collection(firestore, collectionName),
    ...flatten(Object.keys(fields).map(indicator => {
      return reduce(fields[indicator], (predicates, value, fieldName) => {
        predicates.push(where(fieldName, indicator, value))
        return predicates
      }, [])
    })),
    ...order.map(([field, direction = 'asc']) => orderBy(field, direction)),
    limit(queryLimit)
  )
}
const getCollection = collectionName => {
  if (!collectionName) return
  if (Object.values(colls).indexOf(collectionName) === -1) throw new Error(`"${collectionName}" not found in colls map`)
  return collection(firestore, collectionName)
}
const getDocument = (collectionName, documentId) => {
  if (!documentId) throw new Error(`documentID is required`)
  if (Object.values(colls).indexOf(collectionName) === -1) throw new Error(`"${collectionName}" not found in colls map`)
  return doc(firestore, `${collectionName}/${documentId}`)
}
export const updateDocument = async (collectionName, documentId, data) => {
  const document = getDocument(collectionName, documentId)
  return document && await updateDoc(document, data)
}
export const addDocument = async (collectionName, data) => {
  const collection = getCollection(collectionName)
  return data && collection && await addDoc(collection, {
    ...data, createdTs: now(), updatedTs: now(),
  })
}
export const setDocument = async (collectionName, documentId, data) => {
  const document = getDocument(collectionName, documentId)
  return document && await setDoc(document, data)
}
export const deleteDocument = async (collectionName, documentId) => {
  const document = getDocument(collectionName, documentId)
  return document && await deleteDoc(document)
}
