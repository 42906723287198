import React from 'react'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '@omo/lib/firebase'
import Dashboard from './Dashboard'
import Landing from './Landing'

export default function Home() {
  const [user] = useAuthState(auth)
  return (
    <div>
      {
        user && !user.isAnonymous
        ? <Dashboard />
        : <Landing />
      }
    </div>
  )
}

