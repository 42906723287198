import React, {useState} from 'react'
import {Button, Divider, Drawer, Input, List, Space, Typography} from 'antd'
import {AimOutlined, PlusOutlined} from '@ant-design/icons'
import {addDocument, buildQuery, colls} from '@omo/lib/firestore.db'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth, ga} from '@omo/lib/firebase'
import useQueryFirestore from '@omo/hooks/useQueryFirestore'
import {notifyAndTrack} from './notification'
const audienceList = [
  ['women', {pin: true, label: 'women'}],
  ['men', {pin: true, label: 'men'}],
  ['parents', {pin: true, label: 'parents'}],
  ['students', {pin: true, label: 'students'}],
]
const AudienceDrawer = ({placement, onSelect}) => {
  const [user] = useAuthState(auth)
  const [show, setShow] = useState(false)
  const [pairs = []] = useQueryFirestore(
  buildQuery(
    colls['user-audience'],
    {'==': {userId: user.uid}},
    {
      limit: 50,
      order: [['label', 'asc']]}
  ))
  const [audience, setAudience] = useState('')
  const [newAudience, setNewAudience] = useState('')
  const onAudienceInputChange = e => setNewAudience(e.target.value)
  const onClickSaveAudience = () => newAudience && newAudience.length >= 3 && saveNewAudience(newAudience)
  const onClose = () => setShow(false)
  const saveNewAudience = label => {
    if (!user) return
    addDocument(
      colls['user-audience'],
      {label, userId: user.uid,
      }).then(() => notifyAndTrack.success({message: 'Audience Saved'}))
  }
  const onAudienceClick = value => {
    onSelect(value)
    setAudience(value)
    setShow(false)
  }
  const onClickTrigger = (e) => {
    setShow(!show)
    ga.click(e)
  }
  return (
    <>
      <Typography.Text
        title="Open Audience Drawer"
        type="success"
        onClick={onClickTrigger}
      >
        <AimOutlined />
        &nbsp;&nbsp;{audience || 'Set Audience'}
      </Typography.Text>
      <Drawer
        title="Choose an Audience For your Ad"
        open={show}
        onClose={onClose}
        placement={placement}
      >
        <div className="ad-audience-wrapper">
          <List
            dataSource={audienceList.concat(pairs)}
            renderItem={
              ([, {pin, label}]) => (
                <Button
                  size="large"
                  className="audience-list-item"
                  block
                  ghost
                  onClick={() => onAudienceClick(label)}
                  icon={<PlusOutlined />}>
                  {label}
                </Button>)}
          />
          <Divider />
          <Space>
            <Input
              size="large"
              placeholder="Add new audience"
              onChange={onAudienceInputChange}
            />
            <Button
              size="large"
              type={newAudience && newAudience.length >= 3 && 'primary'}
              onClick={onClickSaveAudience}>
              Save
            </Button>
          </Space>
        </div>
      </Drawer>
    </>
  )
}

export default AudienceDrawer
