import React, {useState} from 'react'
import {Button, Card, notification, Typography} from 'antd'
import {auth, ga} from '@omo/lib/firebase'
import {addDocument, buildQuery, colls} from '@omo/lib/firestore.db'
import {useAuthState} from 'react-firebase-hooks/auth'
import useQueryFirestore from '@omo/hooks/useQueryFirestore'
import {ProgressBar} from './ProgressBar'
import {CheckOutlined} from '@ant-design/icons'

export default function EarlyAccess({template, website, live}) {
  const [user] = useAuthState(auth)
  const [pairs = [], dataLoading] = useQueryFirestore(
    buildQuery(
      colls['wait-list'],
      {'==': {template, userId: user.uid}}))
  const [isSubscribed, setSubscribed] = useState(false)
  if (live) return null
  if (!user) return null
  const onClick = e => {
    setSubscribed(true)
    addDocument(colls['wait-list'], {
      template, userId: user.uid, website,
    })
      .then(() => notification.success({
        message: "You'll be notified.",
        placement: 'top',
      }))
      .then(() => ga.click(e, {template}))
  }
  return (
    <section>
      <Card title="Get Early Access" extra={
        dataLoading ? <ProgressBar visible float={false} /> :
        <Button
          disabled={isSubscribed || pairs.length}
          onClick={onClick}
          size="medium"
          type="primary">
          Join List
        </Button>
      }>
        <Card.Meta
          title=""
          description={ !pairs.length ?
            "We are working on this feature. Get on the list for early access." :
            <Typography.Text type="success"><CheckOutlined /> You'll be notified when this feature is ready.</Typography.Text>
        }
        />
      </Card>
    </section>
  )
}