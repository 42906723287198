import React, {useContext, useState} from 'react'
import {Button, Col, Radio, Row} from 'antd'
import take from 'lodash/take'
import {ProgressBar} from './ProgressBar'
import {WindowContext} from './context'

const isPro = false
const Tone = ({tones, disabled, onSelect, tip, processing}) => {
  const [tone, setTone] = useState()
  const [toneInternal, setToneInternal] = useState()
  const [isMobile] = useContext(WindowContext)
  const onToneChange = e => {
    if (!e.target.value) return
    onSelect(e.target.value)
    setToneInternal(e.target.value)
    setTone('')
  }
  return (
    <div>
      {
        isPro ? (
          <Row gutter={[4,4]}>
            {
              tones.map(tone => {
                return <Col span={8}><Button className="tone-button" block>{tone}</Button></Col>
              })
            }
          </Row>
        ) : (
          <Radio.Group
            value={tone}
            disabled={disabled}
            className="tone-radio-group"
            options={take(tones, isMobile ? 4 : 4).map(label => ({
              label: (label.toLowerCase() === toneInternal) && processing
                ? <ProgressBar visible={true} float={false} />
                : label,
              value: label.toLowerCase(),
            }))}
            onChange={onToneChange}
            optionType="button"
            size="large"
          />
        )
      }
      {tip}
    </div>
  )
}

export default Tone
