import React, {useState} from 'react'
import {Button, Popover, Space, message} from 'antd'
import {
  DeleteOutlined,
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined
} from '@ant-design/icons'
import {colls, deleteDocument, updateDocument} from '@omo/lib/firestore.db'

export default function ContentActions({children, content, contentId, collection, allowSave}) {
  const [open, setOpen] = useState(false)
  const onLike = like => updateDocument(colls[collection], contentId, {like})
    .then(() => message.info(like ? 'Liked' : 'Disliked'))
    .catch(() => message.error('Try again later'))
  const onClickDelete = () => {
    return deleteDocument(colls[collection], contentId)
      .then(() => message.success('Deleted'))
      .catch(() => message.error('Oops, unable to delete'))
  }
  const onClickSave = () => updateDocument(colls[collection], contentId, {save: true})
    .then(() => message.success('Saved'))
    .catch(() => message.error('Oops, try again later'))
  const handleOpenChange = open => setOpen(open)
  return (
    <Popover
      content={
        <div className="space space-between action-strip">
          <Button
            icon={<DeleteOutlined />}
            onClick={onClickDelete}
          />
          <Space>
            <Button
              icon={content?.like === false ? <DislikeFilled /> : <DislikeOutlined />}
              onClick={() => onLike(false)}
            />
            <Button
              icon={content?.like === true ? <LikeFilled /> : <LikeOutlined />}
              onClick={() => onLike(true)}
            />
            <Button
              hidden={!allowSave}
              disabled={content?.save}
              onClick={() => onClickSave(true)}
            >Save</Button>
          </Space>
        </div>
      }
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="topLeft"
    >
      {children}
    </Popover>
  )
}