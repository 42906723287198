import React, { useState } from 'react'
import { createUserWithEmailAndPassword, updateProfile, setPersistence, browserLocalPersistence } from 'firebase/auth'
import {Typography, Button, Input, Space, Divider, notification} from 'antd'
import { useAuthState } from 'react-firebase-hooks/auth'
import {auth, ga} from '@omo/lib/firebase'
import {Link, Redirect} from 'wouter'
import {LockOutlined, MailOutlined} from '@ant-design/icons'
import LoginLinks from '@ai-antd/components/LoginLinks'

const signUpEmail = async (e, p, d) => {
  if (!e || !p || !d) return notification.warning({message: 'Missing Info', description: 'All fields are required.'})
  await setPersistence(auth, browserLocalPersistence)
  createUserWithEmailAndPassword(auth, e, p)
    .then(() => auth.currentUser && updateProfile(auth.currentUser, {displayName: d}))
    .then(() => ga.setUserId(auth.currentUser.uid))
    .catch(err => {
      switch (err.code) {
        case 'auth/email-already-in-use':
          notification.error({message: 'Email is Already In Use'})
          break
        case 'auth/weak-password':
          notification.error({message: 'Weak Password', description: 'Password must be at least 6 characters.'})
          break
        default:
          notification.error({message: 'Sign Up Failed', description: 'Try again later'})
      }
      console.log('err', {...err})
    })
}
export default function SignUp({appName}) {
  const [user] = useAuthState(auth);
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  if (user && !user.isAnonymous) return <Redirect to='/' />
  return (
    <section style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
      <Typography.Title level={2} style={{margin: '20px 40px 0'}}>Take Productivity To The Next Level.</Typography.Title>
      <Space direction="vertical" align="center" style={{margin: ' 20px auto 0', width: '100%'}}>
        <div>
          <Space direction="vertical">
            <Divider>Create Free Account</Divider>
            <Input size="large" prefix={<MailOutlined />} onChange={e => setDisplayName(e.target.value)} placeholder="Name" />
            <Input size="large" prefix={<MailOutlined />} onChange={e => setEmail(e.target.value)} placeholder="Email" />
            <Input size="large" prefix={<LockOutlined />} onChange={e => setPassword(e.target.value)} placeholder="Password" type="password" />
            <Button size="large" block onClick={() => signUpEmail(email, password, displayName)} type="primary">Sign Up</Button>
            <Link to="/login" style={{color: 'green', marginTop: '10px', display: 'inline-block', textDecoration: 'underline'}}>I already have an account</Link>
            <Divider />
            <LoginLinks appName={appName} />
          </Space>
        </div>
      </Space>
    </section>
  )
}