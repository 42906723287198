import React from 'react'
import {Divider, Space, Tabs, Typography} from 'antd'
import {LinkedinOutlined, TwitterOutlined} from '@ant-design/icons'

const getTabs = () => {
  return [
    {
      label: 'About',
      key: 'about',
      children: <section className="about-tab-wrapper">
        <Typography.Title level={5}>AI is The Future And You Should have Access.</Typography.Title>
        <Typography.Paragraph>
          Ai is revolutionizing business around the world, enabling people work faster, smarter, and more efficiently. But only if you can afford the price of entry.
        </Typography.Paragraph>
        <Typography.Paragraph>
          With this project I'll bring the latest developments in artificial intelligence, the most useful parts, to individuals and businesses that might otherwise be left out.
        </Typography.Paragraph>
        <Typography.Paragraph>
          I built ai-ads.ng to democratize access to useful AI. And for as long as I can, everything will be free for individuals and small businesses.
        </Typography.Paragraph>
        <Divider />
        <Typography.Title level={5}>Emmanuel Momoh</Typography.Title>
        <Typography.Paragraph>
          Dad, software engineer, and builder<br/>Possibly, the last Nigerian in San Francisco.
        </Typography.Paragraph>
        <Space>
          <a href="https://twitter.com/emomoh" target="_blank" rel="noreferrer">
            <TwitterOutlined className="contact-icon" />
          </a>
          <a href="https://linkedin.com/in/emmanuelmomoh" target="_blank" rel="noreferrer">
            <LinkedinOutlined className="contact-icon" />
          </a>
        </Space>
      </section>
    },
    {
      label: 'Terms',
      key: 'terms',
      children: <section className="about-tab-wrapper"></section>
    },
    {
      label: 'Contact',
      key: 'contact',
      children: <section className="about-tab-wrapper"></section>
    },
    {
      label: 'FAQ',
      key: 'faq',
      children: <section className="about-tab-wrapper">

      </section>
    }
  ]
}


const About = ({tabKey = 'about'}) => {
  return <Tabs
    defaultActiveKey={tabKey}
    centered
    items={getTabs()}
  />
}

export default About
