import React from 'react'
import {Tabs, Tag} from 'antd'
import {RequireAuth} from '@ai-antd/components/RequireAuth'
import ContentList from '@ai-antd/components/ContentList'
import Editor from '@ai-antd/components/Editor'
import EarlyAccess from '@ai-antd/components/EarlyAccess'

const Workspace = ({app, ...props}) => {
  return (
    <RequireAuth>
      <section>
        <Tabs
          items={
            [
              {
                label: 'Create',
                key: 'create',
                disabled: props.live === false,
                children: (props.live === true && <Editor {...props} />) || null
              },
              {
                label: 'Saved',
                key: 'saved',
                disabled: props.live === false,
                children: (props.live === true && <ContentList template={props.template} showSavedOnly />) || null
              },
            ]
          }
          tabBarExtraContent={<Tag>{props.title}</Tag>}
        />
        {props.live === false && <EarlyAccess {...props} website={app.website} />}
      </section>
    </RequireAuth>
  )
}

export default Workspace
