import {useCollection} from 'react-firebase-hooks/firestore'
import {toDataPairs} from '@omo/lib/firestore.db'

const useQueryFirestore = query => {
  const [querySnapshot, isLoading, error] = useCollection(query)
  error && console.log('db error', error)
  return [toDataPairs(querySnapshot), isLoading, error]
}

export default useQueryFirestore
