import React from 'react'
import {Splash} from '@ai-antd/components/Splash'
import {useAuthState} from 'react-firebase-hooks/auth'
import { signInAnonymously } from 'firebase/auth'
import {auth} from '@omo/lib/firebase'
import {browserLocalPersistence, setPersistence} from 'firebase/auth'
import Loading from '@ai-antd/components/Loading'
import app from '../App.json'

const signInAnon = async () => {
  await setPersistence(auth, browserLocalPersistence)
  signInAnonymously(auth).then(user => console.log('anonymous'))
}

const Landing = () => {
  const [user, loading, error] = useAuthState(auth)
  if (!user && !loading && !error) signInAnon()
  if (!user) return <Loading />
  return (
    <div>
      <Splash app={app} />
    </div>
  )
}

export default Landing
