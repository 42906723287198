import React from 'react'
import {Col, Row} from 'antd'
import {FeatureCard} from './FeatureCard'

const GroupTab = ({features}) => {
  return (
    <Row gutter={[12, 12]}>
      {
        features.map(feature => (
          <Col sm={24} md={8} lg={8} xl={8} xxl={6} key={feature.template}>
            <FeatureCard feature={feature} />
          </Col>
        ))
      }
    </Row>
  )
}

export default GroupTab
