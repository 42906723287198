import React from 'react'
import reduce from 'lodash/reduce'
import Icon from '@ai-antd/components/Icon.js'
import {EyeOutlined, HomeOutlined, UserOutlined} from '@ant-design/icons'
import NavLabel from '@ai-antd/components/NavLabel'
import app from './App.json'

const dashboardNav = reduce(app.dashboard, (items, {group: key, title: label, icon = null, features = []}) => {
  items.push({type: 'divider'})
  items.push({
    key,
    icon: <Icon name={icon} />,
    label,
    children: features.map(({template, title: label, live}) => (
      {
        key: template,
        label: <NavLabel template={template} label={label} live={live} />,
        danger: live === false,
        title: label
      }
    ))}
  )
  return items
}, [])
export const navItems = [
  {
    key: 'home',
    icon: <HomeOutlined />,
    label: 'Home',
    title: 'Home',
  },
  ...dashboardNav,
  {type: 'divider'},
  {
    key: 'account',
    icon: <UserOutlined />,
    label: 'Account',
  },
  {type: 'divider'},
  {
    key: 'about',
    icon: <EyeOutlined />,
    label: 'About',
  },
]