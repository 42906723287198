import React, {useContext} from 'react'
import {Button, Card, Tag, Typography} from 'antd'
import {useLocation} from 'wouter'
import {WindowContext} from './context'

export const FeatureCard = ({feature}) => {
  const [, setLocation] = useLocation()
  const [isMobile] = useContext(WindowContext)
  const {title, template, description} = feature
  return (
    <Card
      className="card-rounded"
      title={<Typography.Title level={5}><Tag>ai</Tag> {title}</Typography.Title>}
      bordered={!isMobile}
      extra={<Button onClick={() => setLocation(template)}>Go</Button>}
      hoverable={!isMobile}
    >
      <Typography.Text type="secondary" className="feature-card-description">{description}</Typography.Text>
    </Card>
  )
}