import React from 'react'
import {Link} from 'wouter'
import {Typography} from 'antd'
import {ga} from '@omo/lib/firebase'

const tldStyle = {color: "green"}
const Logo = ({name, domain = [], showTLD = false}) => {
  const tld = showTLD && domain.split('.').pop()
  return (
    <Link to="/" className="logo-wrapper cursor-pointer" onClick={ga.click}>
      <Typography.Text className="cursor-pointer" title="App Logo">
        {name}
        {showTLD && <code style={tldStyle}>{tld}</code>}
      </Typography.Text>
    </Link>
  )
}

export default Logo
