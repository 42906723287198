import React, {useContext, useState} from 'react'
import {Divider, Input, Space, Typography, Row, Col, Tag, Tooltip} from 'antd'
import ContentList from './ContentList'
import * as api from '@omo/lib/callable'
import uniq from 'lodash/uniq'
import {urlFind} from '@omo/lib/regex'
import {ProgressBar} from './ProgressBar'
import Tips from './Tips'
import AudienceDrawer from './AudienceDrawer'
import Tone from './Tone'
import RandomPrompt from './RandomPrompt'
import {notifyAndTrack} from './notification'
import {ga} from '@omo/lib/firebase'
import {WindowContext} from './context'

const rowStyle = { display: 'flex' }
const adsListWrapper = {width: '100%'}
const ITEM = {
  product: 'Product',
  book: 'Book',
  blog: 'Blog',
  movie: 'Movie',
  song: 'Song',
  photograph: 'Photograph',
}
const tagMap = {
  'og:description': 'description',
  'twitter:description': 'description',
  'description': 'description',
  'og:title': 'title',
  'twitter:title': 'title',
  title: 'title',
  'og:image:secure_url': 'image',
  'og:image': 'image',
  'og:price:amount': 'price',
  price: 'price',
  'og:price:currency': 'currency',
  currency: 'currency',
  'og:site_name': 'siteName',
  'og:type': 'type',
  'og:url': 'url',
  'al:web:url': 'url',
}
const initializeFields = tagMap => {
  const fields = {}
  uniq(Object.values(tagMap)).forEach(field => fields[field] = [])
  return fields
}
const extractLinkData = metaTags => {
  let fieldsFound = 0
  const data = initializeFields(tagMap);
  Object.keys(tagMap).forEach(tagName => {
    if (metaTags?.[tagName]) {
      data[tagMap[tagName]].push(metaTags?.[tagName]?.content)
      fieldsFound += 1
    }
  })
  return fieldsFound ? data : null
}
const isValidUrl = (urlString, httpsOnly) => {
  let url
  try {
    url = new URL(urlString)
  }
  catch(e){
    return false;
  }
  return httpsOnly ? url.protocol === 'https:' : true
}
const getDescription = linkData => linkData?.description?.[0] || linkData?.title?.[0] || linkData?.siteName?.[0]
const processLink = async link => {
  if (!link) return
  if (!isValidUrl(link, true)) {
    notifyAndTrack.error({
      message: 'Invalid Link',
      description: 'The link cannot be used. Please try a different one.'
    })
    return
  }
  const metaTags = await api.getLinkMeta(link)
    .catch(e => {
      notifyAndTrack.error({
        message: 'Unusable Link',
        description: e?.message === 'internal' ? 'Please try a different link' : e?.message,
      })
    })
  if (!metaTags) return // setShowLinkProgress(false)
  console.log('result', metaTags?.data)
  return extractLinkData(metaTags.data)
}
const Editor = ({group, title, template, editorOptions = {}, promptOptions = {}, modelOptions = {}, onRequest, onResponse, isDemo}) => {
  const [isMobile] = useContext(WindowContext)
  let editor
  const {
    tones = [],
    audience: enableAudience = false,
  } = promptOptions;
  const {
    label = "Enter your prompt below",
    placeholder = 'Write a tagline for an ice cream shop',
    initialValue = '',
    maxLength = 240,
    editorSize = { minRows: 5, maxRows: 8 },
    parseLink: enableParseLink = false,
    random = [],
  } = editorOptions;
  const [linkData, setLinkData] = useState({});
  const [description, setDescription] = useState(initialValue)
  const [audience, setAudience] = useState('')
  const [processing, setProcessing] = useState(false)
  const [showLinkProgress, setShowLinkProgress] = useState(false)
  const [requestCount, setRequestCount] = useState(0)
  const onDescriptionChange = async e => {
    const text = e.target.value
    if (!enableParseLink) return setDescription(text)
    if (!urlFind.any.test(text)) return setDescription(text)
    const [link] = text.match(urlFind.any)
    if (!link) return setDescription(text)
    if (link !== text) return setDescription(text)
    setShowLinkProgress(true)
    const linkData = await processLink(link)
    setShowLinkProgress(false)
    if (!linkData) return notifyAndTrack.warning({
      message: 'Description Not Found at link',
      description: 'Unable to get description from link',
    })
    setLinkData(linkData)
    setDescription(getDescription(linkData))
  }
  const sendRequest = async tone => {
    if (!description) {
      editor?.focus()
      return !isMobile && notifyAndTrack.info({
        message: 'Please type a prompt',
        placement: 'top',
      })
    }
    setProcessing(true)
    setRequestCount(requestCount + 1)
    onRequest && onRequest(template)
    const promptOptions = {
      prompt: description,
      template,
      tone,
      audience,
      item: ITEM.product,
      linkData,
    }
    api.completion(
      {
        promptOptions,
        modelOptions: {...modelOptions},
      },
    ).then(({data}) => {
      setProcessing(false)
      onResponse && onResponse(data, promptOptions, {...modelOptions})
      ga.log('api-success-content', {value: template})
    }).catch(e => {
      setProcessing(false)
      notifyAndTrack.error({
        track: 'API call to content failed',
        code: e.code,
        message: 'An error occurred.',
        description: e?.message === 'internal' ? 'Unable to complete your request. Try again later.' : e?.message,
      })
      onResponse && onResponse()
    })
  }
  return (
    <div>
      <Row>
        <Col xs={24} sm={12} md={12} lg={10} xl={10} xxl={8}>
          <Space direction="vertical" size="middle" style={rowStyle}>
            <Typography.Title level={2} type="secondary" hidden={!isDemo}>{title}</Typography.Title>
            <Space>
              <Typography.Text type={!!description ? "secondary" : "primary"}>{label}</Typography.Text>
              {enableParseLink && [
                <Typography.Text key="or" type="secondary">or</Typography.Text>,
                <Typography key="paste-a-link">paste a link</Typography>
              ]}
            </Space>
            <div className="ad-description-wrapper">
              <ProgressBar loop visible={showLinkProgress} />
              <Input.TextArea
                ref={editorRef => editor = editorRef}
                placeholder={!showLinkProgress ? placeholder : ''}
                size="large"
                onChange={onDescriptionChange}
                autoSize={editorSize}
                value={description}
                maxLength={maxLength}
              />
              <div className="editor-actions-strip">
                <div>
                  { !!description
                    ? <Tag className="editor-action-button cursor-pointer" onClick={() => setDescription('')}>CLEAR</Tag>
                    : <RandomPrompt prompts={random} hidden={!!description} onSelect={setDescription} />}
                </div>
                <Tips group={group} />
              </div>
            </div>
            {!!tones.length && <Tone
              disabled={processing}
              processing={processing}
              tones={tones}
              onSelect={sendRequest}
              tip={
              <Tooltip title="Choose a Tone" open={!requestCount && !!description} placement="bottomLeft">
                <span style={{display: 'block'}} />
              </Tooltip>
              }
            />
            }
            <div className="editor-advanced-strip">
              {enableAudience && <AudienceDrawer
                placement={isMobile ? "bottom" : "right"}
                onSelect={setAudience}
              />}
              <label />
            </div>
          </Space>
        </Col>
        <Col xs={0} sm={12} md={12} lg={10} xl={10} xxl={8} />
        <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={8} />
      </Row>
      {!isDemo && <Row>
          <Col xs={24} sm={12} md={12} lg={10} xl={10} xxl={8}>
            <Space direction="vertical" size="middle" style={adsListWrapper}>
              <Divider />
              <ContentList template={template} />
            </Space>
          </Col>
          <Col xs={0} sm={12} md={12} lg={10} xl={10} xxl={8}></Col>
          <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={8} ></Col>
        </Row>
      }
    </div>
  )
}

export default Editor
