import React from 'react'
import {Alert, List, Typography} from 'antd'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '@omo/lib/firebase'
import ContentListItem from './ContentListItem'
import {buildQuery, colls} from '@omo/lib/firestore.db'
import useQueryFirestore from '@omo/hooks/useQueryFirestore'

const getFields = ({user, template, showSavedOnly}) => {
  const fields = {userId: user.uid, "templateParams.template": template}
  if (showSavedOnly) fields.save = true
  return fields
}
const getListItem = ([contentId, content]) => <ContentListItem key={contentId} contentId={contentId} content={content} />
const ContentList = ({template, showSavedOnly = false}) => {
  const [user] = useAuthState(auth)
  const [pairs = [], adsLoading, error] = useQueryFirestore(
    buildQuery(
      colls['openai-content'],
      {'==': getFields({user, template, showSavedOnly})},
      {order: [['createdTs', 'desc']]}))
  if (error) return <Alert message="Unable to load content at this time." type="error" />
  if (!pairs.length && !adsLoading) return (
    <Typography.Text type="secondary">
      Your content will show here.
    </Typography.Text>
  )
  return  <List dataSource={pairs} renderItem={getListItem} />
}

export default ContentList
