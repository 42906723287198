import React from 'react'
import {Menu} from 'antd'
import {useLocation} from 'wouter'

const menuStyle = {
  borderRight: 0,
}
const NavMenu = ({onNav, items = []}) => {
  const [location, setLocation] = useLocation()
  const onMenuClick = ({key}) => {
    setLocation(`/${key}`)
    onNav && onNav(key)
  }
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[location]}
      style={menuStyle}
      onClick={onMenuClick}
      items={items}
    />
  )
}

export default NavMenu