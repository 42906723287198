import React from 'react'
import {Typography} from 'antd'
import {colls} from '@omo/lib/firestore.db'
import ContentActions from './ContentActions'

export default function BrainstormPrompt({messageId, message = {}}) {
  return (
    <ContentActions
      contentId={messageId}
      content={message}
      collection={colls["openai-brainstorm"]}
    >
    <span className="brainstorm-message right">
      {
        <Typography.Paragraph copyable={false}>
          <p className="content-text">{message.promptOptions?.prompt}</p>
        </Typography.Paragraph>
      }
    </span>
  </ContentActions>
  )
}