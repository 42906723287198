import React, {useContext, useState} from 'react'
import {Button, Drawer, Input, Typography} from 'antd'
import RandomPrompt from './RandomPrompt'
import Tips from './Tips'
import {urlFind} from '@omo/lib/regex'
import * as api from '@omo/lib/callable'
import uniq from 'lodash/uniq'
import BrainstormThread from './BrainstormThread'
import {BiBrain} from 'react-icons/bi'
import {useLocation} from 'wouter'
import {CheckOutlined, LoadingOutlined, RightOutlined} from '@ant-design/icons'
import {notifyAndTrack} from './notification'
import {auth, ga} from '@omo/lib/firebase'
import {defaultModel} from './SelectModel'
import {WindowContext} from './context'
import {useAuthState} from 'react-firebase-hooks/auth'
const editorSize = { minRows: 1, maxRows: 10 }
const iconStyle = {textAlign: 'right'}
const headerStyle = {backgroundColor: '#000'}
const drawerTransparent = {backgroundColor: 'transparent'}
const tagMap = {
  'og:description': 'description',
  'twitter:description': 'description',
  'description': 'description',
  'og:title': 'title',
  'twitter:title': 'title',
  title: 'title',
  'og:image:secure_url': 'image',
  'og:image': 'image',
  'og:price:amount': 'price',
  price: 'price',
  'og:price:currency': 'currency',
  currency: 'currency',
  'og:site_name': 'siteName',
  'og:type': 'type',
  'og:url': 'url',
  'al:web:url': 'url',
}
const initializeFields = tagMap => {
  const fields = {}
  uniq(Object.values(tagMap)).forEach(field => fields[field] = [])
  return fields
}
const extractLinkData = metaTags => {
  let fieldsFound = 0
  const data = initializeFields(tagMap);
  Object.keys(tagMap).forEach(tagName => {
    if (metaTags?.[tagName]) {
      data[tagMap[tagName]].push(metaTags?.[tagName]?.content)
      fieldsFound += 1
    }
  })
  return fieldsFound ? data : null
}
const isValidUrl = (urlString, httpsOnly) => {
  let url
  try {
    url = new URL(urlString)
  }
  catch(e){
    return false;
  }
  return httpsOnly ? url.protocol === 'https:' : true
}
const ideas = []
const Brainstorm = () => {
  let history = []
  const [user] = useAuthState(auth)
  const [isMobile, width] = useContext(WindowContext)
  const [location] = useLocation()
  const [linkData, setLinkData] = useState({})
  // const [history, setHistory] = useState([])
  const [prompt, setPrompt] = useState()
  const [model] = useState(defaultModel)
  const [processing, setProcessing] = useState(false)
  const [showLinkProgress, setShowLinkProgress] = useState(false)
  const [requestCount, setRequestCount] = useState(0)
  const [show, setShow] = useState(false)
  const setHistory = messages => history = messages
  const onClickShow = () => setShow(!show)
  const onClose = () => setShow(false)
  const getDescription = linkData => linkData?.description?.[0] || linkData?.title?.[0] || linkData?.siteName?.[0]
  const processLink = async link => {
    if (!link) return
    if (!isValidUrl(link, true)) {
      notifyAndTrack.error({
        track: 'Invalid attempt to process link',
        link,
        message: 'Invalid Link',
        description: 'The link cannot be used. Please try a different one.'
      })
      return
    }
    const metaTags = await api.getLinkMeta(link)
      .catch(e => {
        notifyAndTrack.error({
          message: 'Unusable Link',
          description: e?.message === 'internal' ? 'Please try a different link' : e?.message,
        })
      })
    if (!metaTags) return
    return extractLinkData(metaTags.data)
  }
  const onPromptChange = async e => {
    const text = e.target.value
    if (!urlFind.any.test(text)) return setPrompt(text)
    const [link] = text.match(urlFind.any)
    if (!link) return setPrompt(text)
    if (link !== text) return setPrompt(text)
    setShowLinkProgress(true)
    const linkData = await processLink(link)
    setShowLinkProgress(false)
    if (!linkData) return notifyAndTrack.warning({
      message: 'Description Not Found at URL',
      description: 'Unable to get description from the provided URL',
    })
    setLinkData(linkData)
    setPrompt(getDescription(linkData))
  }
  const sendRequest = async (prompt, tone) => {
    if (!prompt) {
      return notifyAndTrack.error({
        message: 'Please enter a prompt',
      })
    }
    setPrompt('')
    setProcessing(true)
    setRequestCount(requestCount + 1)
    const promptOptions = {
      prompt,
      template: 'ai-brainstorm',
      featureTemplate: location.slice(1),
      tone,
      linkData,
    }
    console.log('history', history)
    api.brainstorm(
      {
        promptOptions,
        modelOptions: {
          temperature: 0,
          max_tokens: 180,
          frequency_penalty: 0.3,
          stop: ["Human:", "AI:"],
          model,
        },
        history,
      },
    ).then(() => {
      setProcessing(false)
      ga.log('api-success-brainstorm', {value: promptOptions.template})
    }).catch(e => {
      setProcessing(false)
      notifyAndTrack.error({
        value: 'API call to brainstorm failed',
        code: e.code,
        message: 'An error occurred.',
        description: e?.message === 'internal' ? 'Unable to complete your request. Try again later.' : e?.message,
      })
    })
  }
  return (
    <>
      <Button
        style={iconStyle}
        size="large"
        className="border-none"
        icon={<BiBrain size={26} />}
        onClick={onClickShow}
        ghost
      />
      <Drawer
        width={width > 400 ? 400 : width}
        className="brainstorm-drawer"
        title={
        <div className="space space-center">
          <Typography.Title
            className="drawer-title"
            level={4}>
            AI Brainstorm
          </Typography.Title>
        </div>
        }
        destroyOnClose
        open={show}
        onClose={onClose}
        placement="right"
        closable={false}
        headerStyle={headerStyle}
        drawerStyle={drawerTransparent}
        contentWrapperStyle={drawerTransparent}
        extra={<Button icon={<RightOutlined onClick={onClose} />} />}
      >
        <div className={`brainstorm-wrapper ${isMobile ? "mobile" : "desktop"}`}>
          {user && <BrainstormThread setHistory={setHistory} />}
          <div className="brainstorm-editor-wrapper">
            <div className="space space-center space-end relative">
              <Input.TextArea
                placeholder={!showLinkProgress ? 'Ask a question' : ''}
                onChange={onPromptChange}
                autoSize={editorSize}
                value={prompt}
                maxLength={600}
                className="brainstorm-editor"
                size="large"
              />
              <Button
                className="brainstorm-send-button"
                shape="round"
                disabled={processing || !prompt}
                onClick={() => sendRequest(prompt, '')}
                type="primary"
                icon={processing ? <LoadingOutlined /> :  <CheckOutlined />}
              />
            </div>
            <div className="editor-actions-strip">
              <RandomPrompt prompts={ideas} hidden={!!prompt} onSelect={setPrompt} />
              <Tips group="brainstorm" />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default Brainstorm
