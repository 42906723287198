import React from 'react'
import {Layout, PageHeader, Space} from 'antd'
import {auth} from '@omo/lib/firebase'
import {useAuthState} from 'react-firebase-hooks/auth'
import Loading from '@ai-antd/components/Loading'
import MobileSidebar from '@ai-antd/components/MobileSidebar'
import DesktopSidebar from '@ai-antd/components/DesktopSidebar'
import Logo from '@ai-antd/components/Logo'
import UpsellButton from '@ai-antd/components/UpsellButton'
import Brainstorm from '@ai-antd/components/Brainstorm'
import useWindowSize from '@omo/hooks/useWindowSize'
import {WindowContext} from '@ai-antd/components/context'
import {LeftOutlined} from '@ant-design/icons'

const { Content } = Layout

export function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}

function isInStandaloneMode() {
  return Boolean(
    (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
    || window.navigator.standalone, // Fallback for iOS
  )
}
export default function SiteLayout({name, domain, children, navItems}) {
  const [, loading] = useAuthState(auth)
  const { width, height, isMobile = getView(width) === "MobileView" } = useWindowSize()
  const isStandalone = isInStandaloneMode()
  const screen = [isMobile, width, height, isStandalone]
  if (loading) return <Loading />
  const layoutClassName = isMobile ? 'mobileLayout' : 'desktopLayout'
  const innerLayoutClassName = isMobile ? 'innerMobileLayout' : 'innerDesktopLayout'
  const contentClassName = isMobile ? 'mobileContent' : 'desktopContent'
  const pwaProps = isStandalone ? {onBack: () => window.history.back()} : {}
  return (
    <WindowContext.Provider value={screen}>
      <Layout
        className={layoutClassName}
        style={{width}}
      >
        <PageHeader
          {...pwaProps}
          backIcon={<LeftOutlined />}
          title={
            isMobile
              ? <div className="space space-center">
                <MobileSidebar navItems={navItems} />
                <Logo name={name} domain={domain} showTLD={false} />
              </div>
              :
              <Logo name={name} domain={domain} showTLD={false} />
          }
          extra={isMobile
            ?
            <Brainstorm />
            :
            <Space>
              <UpsellButton />
              <Brainstorm />
            </Space>}
        />
        <Layout className="app-content-layout">
          {!isMobile && <DesktopSidebar navItems={navItems} />}
          <Layout className={innerLayoutClassName}>
            <Content className={contentClassName}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </WindowContext.Provider>
  )
}

