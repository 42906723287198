import React, {useContext} from 'react'
import {Tabs} from 'antd'
import GroupTab from '@ai-antd/components/GroupTab'
import {WindowContext} from '@ai-antd/components/context'
import app from '../App.json'

const getTabs = isMobile => {
  return app.dashboard.map(({group: key, title: label, features}) => (
    {label, key, children: <GroupTab features={features} isMobile={isMobile} />}
    )
  )
}
const Dashboard = () => {
  const [isMobile] = useContext(WindowContext)
  return (
    <div>
      <Tabs
        centered
        items={getTabs(isMobile)}
      />
    </div>
  )
}

export default Dashboard
