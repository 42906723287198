import React from 'react'
import {Layout} from 'antd'
import {useState} from 'react'
import NavMenu from './NavMenu'

const { Sider } = Layout
const siderStyle = {height: "100vh"}

const DesktopSidebar = ({navItems = [], width = 220}) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Sider
      width={width}
      style={siderStyle}
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
    >
      <NavMenu items={navItems} />
    </Sider>
  )
}

export default DesktopSidebar
